.table1 {
  width: 1280px;
  margin: 0 auto;
  /*border-left: 1px solid #8080805E;*/
  /*border-right: 1px solid #8080805e;*/
  text-align: center;
  outline: 1px solid #8080805e;
}

.td1 {
  vertical-align: top;
  border-left: 3px solid white;
  border-top: 1px solid #8080805e;
  border-bottom: 1px solid #8080805e;
  padding: 6px 15px;
}

/*.tr1 {*/
/*  text-align: center;*/
/*  border: 5px solid white;*/
/*}*/

thead>.tr1{
  background-color: rgba(203, 178, 124, 0.19);
}

.tr1:nth-child(2n){
  background-color: rgba(203, 178, 124, 0.19);
}

.part-size {
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: calc(2em + 0px);
  margin: 0.6em 0;
  /*padding: 0 10px;*/
  border: 1px solid #ccc;
  border-radius: 0.7em;
  background: #fff;
  resize: none;
  outline: none;
}

.part-size[required]:hover,
.part-size[required]:focus {
  /*border-color: #00bafa;*/
  border-color: #fecb00;
  border-width: 1px;
}

.part-size[required]:hover + label[placeholder]:before,
.part-size[required]:focus + label[placeholder]:before {
  /*color: #00bafa;*/
  color: #fecb00;
  /*font-weight: 500 ;*/
  background: #fff;
}

.part-size[required]:focus + label[placeholder]:before,
.part-size[required]:hover + label[placeholder]:before,
.part-size[required]:valid + label[placeholder]:before,
.part-size:read-only + label[placeholder]:before {
  transition-duration: 0.2s;
  /*color: #898989;*/
  transform: translate(-2em, -1em) scale(1, 1);
  background: #fff;
}

.part-size[required]:invalid + label[placeholder][alt]:before {
  content: attr(value);
}

.part-size[required] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.1em;
  margin-top: calc(-2.1em - 2px);
  margin-bottom: calc((1.6em - 1em) + 2px);
  background-color: inherit;
}

.part-size[required] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(1em + 2px);
  padding: 0 2px;
  color: #898989;
  border-radius: 0.3em;
  /*color: red;*/
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  /*background-image: linear-gradient(to bottom, #fff, #fff);*/
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.part-size[required]:invalid {
  background-color: #edc8d6;
}

/* Chrome, Safari, Edge, Opera */
.part-size::-webkit-outer-spin-button,
.part-size::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.part-size {
  -moz-appearance: textfield;
}

/*checkbox swipe*/
.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #cccccc;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type="checkbox"] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #898989;
  transition-duration: 0.2s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #fecb00;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #898989;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #e7c000;
}

/*checkbox part*/
.checkbox-wrapper-31:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.checkbox-wrapper-31 .background {
  fill: #cccccc;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31:hover .background {
  fill: #898989;
  transition-duration: 0.2s;
}

.checkbox-wrapper-31 .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper-31 input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  -webkit-appearance: none;
}

.checkbox-wrapper-31 input[type="checkbox"]:hover {
  cursor: default;
  /*cursor: pointer;*/
}

.checkbox-wrapper-31 input[type="checkbox"]:checked + svg .background {
  fill: #fecb00;
}

.checkbox-wrapper-31 input[type="checkbox"]:checked:hover + svg .background {
  fill: #e7c000;
}

.checkbox-wrapper-31 input[type="checkbox"]:checked + svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-31 input[type="checkbox"]:checked + svg .check {
  stroke-dashoffset: 0;
}

.icons {
  display: block;
  text-align: left;
}

.icons > svg {
  height: 16px;
  width: 16px;
}

.icons > svg > path {
  fill: #898989;
  transition-duration: 0.5s;
}

.icons > span {
  display: inline-block;
  margin-left: 5px;
  color: #494949;
  font-size: small;
  transition-duration: 0.5s;
  cursor: default;
}

.icons:hover > svg > path {
  fill: #fecb00;
  transition-duration: 0.5s;
}

.icons:hover > span {
  /*color: #FECB00;*/
  transition-duration: 0.5s;
}

.obrob {
  text-align: right;
  display: inline-block;
  color: #494949;
  font-size: small;
  cursor: default;
}

/*not work*/
.d-none:hover > .fire > .active-edge {
  fill: red;
}

.edge-select {
  /*box-sizing: border-box;*/
  text-align: center;
  font-weight: bold;
  font-size: small;
  width: 85%;
  height: calc(1.6em + 0px);
  /*margin: 0.6em 0;*/
  /*padding: 0 10px;*/
  border: 1px solid #ccc;
  border-radius: 0.7em;
  background: #fff;
  resize: none;
  outline: none;
}
