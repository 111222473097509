.preview>thead>tr>th {
    border: 1px solid green;
    text-align: center;
    font-size: small;
    background-color: #F5F1E7;
}

.preview>tbody>tr>td {
    border: 1px solid green;
    text-align: center;
    font-size: small;
}
.preview{
    /*display: inline-block;*/
    margin-bottom: -1px;
}