.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*bootstrap*/
.left-side-bar.nav-pills .nav-link.active {
  background-color: #ffd600;
  fill: #3c3f41;
  border-radius: 0;
}

.left-side-bar.nav-pills .nav-link {
  background-color: #3c3f41;
  fill: whitesmoke;
  border-radius: 0;
  margin-bottom: 1px;
}

.left-side-bar.nav-pills .nav-link:not(.active):hover {
  fill: #ffd600;
}

.st0 {
  fill: #ffd600;
}
.st1 {
  fill: whitesmoke;
}

/*spin*/
.spin-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #080705;

  span {
    color: white;
    font-size: xxx-large;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .spinner {
    position: absolute;
    height: 60px;
    width: 60px;
    border: 3px solid transparent;
    border-top-color: #a04668;
    top: 50%;
    left: 50%;
    margin: -30px;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 3px solid transparent;
      border-radius: 50%;
    }

    &:before {
      border-top-color: #254e70;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      animation: spin 3s linear infinite;
    }

    &:after {
      border-top-color: #fffbfe;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
      animation: spin 4s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*spin*/

button.up {
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 2rem;
  position: fixed;
  bottom: -5rem;
  right: 1rem;
  transition: 0.2s all ease-in-out;
  background-color: #fecb00;
  font-weight: bold;
  color: black;
  border: none;
}

button.up.show {
  bottom: 0rem;
}
